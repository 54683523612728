export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyASu684g9z8SsTsFFFXVIbZyTnXcXHZEhc",
  authDomain: "app.test.bookcreator.com",
  databaseURL: "https://bookcreator-test.firebaseio.com",
  storageBucket: "bookcreator-test.appspot.com",
  messagingSenderId: "925484038468",
};

export const PAYMENTS_DOMAIN = "https://payments.test.bookcreator.com";
export const LIBRARIES_DOMAIN = "https://libraries.api.test.bookcreator.com";
export const ANALYTICS_DOMAIN = "https://analytics.api.test.bookcreator.com";
export const BUTTON_DOMAIN = "https://button.api.test.bookcreator.com";
export const BUTTON_SDK_DOMAIN = "https://sdk.test.bookcreator.com";
export const BUTTON_SDK_VERSION = 1;
export const BOOKCREATOR_ORGS_API_URL = "https://organisations.api.test.bookcreator.com";
export const BOOKCREATOR_API_URL = "https://api.test.bookcreator.com";
export const BOOKCREATOR_ASSETS_URL = "https://assets.api.test.bookcreator.com";
export const BOOKCREATOR_APP_URL = "https://app.test.bookcreator.com";
export const FULLSTORY_ORG_ID = "o-1H302S-na1";

export const GA_ID = "";

export const SENTRY_URL = "";

export const AUTH_COOKIE_NAME = "__admin_session-test";
export const APP_AUTH_COOKIE_NAME = "__session-test";
export const AUTH_COOKIE_DOMAIN = "test.bookcreator.com";
export const LTI_DOMAIN = "lti.test.bookcreator.com";

export const RECURLY_URL = "https://bookcreator-test.recurly.com/subscribe/";
export const OFFICE_365_CLIENT_ID = "ca5b0f72-80b2-4b36-acc1-92a8007a92dd";

export const INTERCOM_APP_ID = "getia422";
